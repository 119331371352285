<template>
  <div class="meeting_course_container global_full_screen">
    <div class="meeting_course_formal_tips_save">
      <div class="meeting_course_formal_tips_save_text_second" @click="showvconsole">
        录制总时长：{{ countSecond }}
      </div>
      <div class="meeting_course_formal_tips_save_text">解说词区</div>
      <div class="meeting_course_formal_tips_save_text_second">
        <div class="meeting_course_formal_tips_save_text_second_item">
          当页录制时长：
          <span
            :class="{
              meeting_course_formal_tips_save_text_second_item_second:
                getCurrentTime() >= 50,
            }"
            >{{ getCurrentTime() }}</span
          >秒
        </div>
      </div>
    </div>
    <div class="meeting_course_down_time" v-if="!meetingData?.isWatch">
      <div
        class="meeting_course_down_time_block global_container_center"
        :class="{
          meeting_course_down_time_breathe:
            isInArea(10) && voiceTime.count < getLimitTime(),
          meeting_course_down_time_block_full:
            !isInArea(56) || !getDownTime() || meetingData.meet_info.up_time_int,
        }"
        v-if="isCurrentDoctor()"
      >
        <div
          class="meeting_course_down_time_block_normal global_container_center"
          v-if="!(!getDownTime() || getCurrentTime() >= 59)"
        >
          <template v-if="!meetingData.meet_info.up_time_int">
            <div v-show="!isInArea(56)">倒计时</div>
            <div>
              <span>{{ getDownTime() }}</span>
              <span v-show="!isInArea(56)">秒</span>
            </div>
          </template>
          <template v-else>
            <div v-show="!isInArea(56)">计时</div>
            <div>
              <span>{{ getCurrentTime() }}</span>
              <span v-show="!isInArea(56)">秒</span>
            </div>
          </template>
        </div>
        <div
          class="meeting_course_down_time_block_complete global_container_center"
          v-else
        >
          时间到
        </div>
      </div>
    </div>
    <!-- <div class="meeting_course_title_main">
        <customButton class="meeting_course_step_play" @click="playVoice" v-show="meetingData.meet_info.phase > 0"><div>播放录制内容</div></customButton>
        <div class="meeting_course_step_play_area"></div>
    </div> -->
    <div
      class="meeting_course_room global_meeting_padding"
      :class="{ meeting_button_group_padding_bottom: isShowEssencePage() }"
    >
      <div class="meeting_course_room_proxy global_full_screen">
        <itemScroll ref="itemScroll" :isTop="true">
          <div class="meeting_course_content">
            {{ content }}
          </div>
        </itemScroll>
      </div>
      <div class="meeting_button_group">
        <div
          class="meeting_cream_button"
          v-if="isShowEssencePage()"
          @click="setEssencePage"
        >
          <span
            v-if="
              !(
                meetingData.isWatch &&
                !meetingData.class_info.content[meetingData.doctor_index].essence
              )
            "
            >{{
              meetingData.class_info.content[meetingData.doctor_index].essence
                ? "精华页"
                : "设置精华页"
            }}</span
          >
        </div>
      </div>
      <!-- <div class="global_container_mask"></div> -->
    </div>
    <!-- 进度条 -->
    <!-- <div class="meeting_course_step_progress">
        <div class="meeting_course_step_progress_out">
            <div class="meeting_course_step_progress_in" :style="{width:`${getProgressWidth()}%`}"></div>
        </div>
    </div> -->
    <div class="meeting_course_group global_meeting_padding">
      <!-- <div class="meeting_course_step_detail">
            <div class="meeting_course_step_time">
                <span>倒计时：</span>
                <span>{{getShowContent()}}</span>
                <div class="meeting_course_point_progress_main">
                    <div class="meeting_course_point_progress" :class="{meeting_course_point_progress_start:getProgressWidth()<100}">
                        <div>·</div>
                        <div>·</div>
                        <div>·</div>
                    </div>
                </div>
                <div class="meeting_course_step_progress">
                    <div class="meeting_course_step_progress_out">
                        <div class="meeting_course_step_progress_in" :style="{width:`${getProgressWidth()}%`}"></div>
                    </div>
                    <span>{{getShowContent()}}</span>
                </div>
            </div>
        </div> -->
      <div
        class="meeting_course_prep_step"
        v-if="!haveNotStart() && this.getCourseIndex() > 0"
      >
        <customButton @click="prepCourse"><div>上一页</div></customButton>
      </div>
      <div>
        <customButton
          class="meeting_course_step_play"
          :class="{ disableBtn: isPlay }"
          @click="playVoice"
          v-show="!haveNotStart()"
          ><div>{{ getPlayText() }}</div></customButton
        >
      </div>
      <div v-if="!haveNotStart() && isCurrentDoctor() && !meetingData?.isWatch">
        <customButton class="meeting_course_step" @click="resetRecord"
          ><div>重新录制</div></customButton
        >
      </div>
      <div class="meeting_course_step_button">
        <customButton
          class="meeting_course_step"
          @click="startCourse"
          v-show="haveNotStart()"
          ><div>开始</div></customButton
        >
        <customButton
          class="meeting_course_step"
          @click="endCourse"
          v-show="!haveNotStart()"
          ><div>{{ getButtonText() }}</div></customButton
        >
      </div>
    </div>
    <Popup
      class="p4vw"
      :cfg="popupEssencePage"
      v-if="showEssencePagePopup"
      @doClose="setEssencePageHandle(false)"
      @doSure="setEssencePageHandle(true)"
      >请确认是否设置本页为精华页（仅可选择一页），后续会制作本组解读专家的精华视频合集用于展示，最终提交后不能再进行更换</Popup
    >
    <Popup
      :cfg="popupContent"
      v-if="showPopup"
      @doClose="goToPrepPage(false)"
      @doSure="goToPrepPage(true)"
      >是否保存本页录音？</Popup
    >
    <Popup
      :cfg="moreChatContent"
      v-if="showMoreChatPopup"
      @doClose="this.showMoreChatPopup = false"
      @doSure="startRunRecord"
      >此页为新增内容，请录制音频</Popup
    >
  </div>
</template>
<script>
import customButton from "@/components/unit/customButton";
import itemScroll from "@/components/unit/itemScroll";
import weixin from "@/common/weixin";
import Popup from "@/components/unit/popup";
import recorderH5 from "@/common/recorderH5";
import recorderWx from "@/common/recorder";
export default {
  name: "meetingCourse",
  props: {
    meetingData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      showvconsoleNum: 0,
      popupContent: {
        title: "提示",
      },
      popupEssencePage: {
        title: null,
      },
      // 会议结束后，重新录制时候的弹窗
      moreChatContent: {
        title: "提示",
        button: {
          sure: "确认",
        },
      },
      showEssencePagePopup: false,
      showPopup: false,
      showMoreChatPopup: false,
      voiceTime: {
        maxCount: 59,
        time: null,
        startTime: 0,
        count: 0,
        isOver: false,
        lock: false,
      },
      // 三个状态
      // -1 无状态 0 开始 1 下一步 2 结束 3 重置 4 播放
      config: {
        status: {
          start: 0,
          next: 1,
          end: 2,
          reset: 3,
          play: 4,
        },
      },
      buttonActive: {
        start: 0,
        next: 0,
        reset: 0,
        play: 0,
      },
      content: "",
      clickLock: false,
      isPlay: false, //是否正在播放录音
      isUpload: false, //是否正在上传
      resetVoicesTimes: 0,
      chatContent: null,
      resetIndex: 0,
      repeatCount: 0,
      showDownTimeTip: false,
      isNewContent: false,
      countSecond: "",
      initComplete: false,
      fileNameNumebr: 1, // 初步解决语音文件缓存问题
    };
  },
  components: {
    itemScroll,
    customButton,
    Popup,
  },
  watch: {
    "meetingData.chat_info": {
      handler() {
        this.initRecordTime();
      },
      deep: true,
    },
  },
  created() {
    this.getContent(true);
  },
  methods: {
    showvconsole() {
      // this.showvconsoleNum++;
      // if (this.showvconsoleNum === 5) {
      //   this.showvconsoleNum = 0;
      //   let __vconsole = document.querySelector("#__vconsole");
      //   __vconsole.setAttribute("style", "opacity: 1;z-index:999");
      // }
    },
    isShowEssencePage() {
      // meetingData.meet_info.essence=1 显示精华按钮
      // meetingData.class_info.content[this.meetingData.doctor_index].essence=1 显示精华页这三个按钮的文字反之就是设置精华页
      // meetingData.isWatch=0 决定是否显示设置精华页
      let item = this.meetingData?.class_info.content[this.meetingData.doctor_index];
      // let item = this.meetingData.class_info.content[0];
      return !!this.meetingData.meet_info.essence && item.template_key !== "DOCTERPAGE";
    },
    setEssencePage() {
      if (this.meetingData.class_info.content[this.meetingData.doctor_index].essence)
        return;
      this.showEssencePagePopup = true;
    },
    setEssencePageHandle(status) {
      let self = this;
      if (!status) {
        this.showEssencePagePopup = false;
        return;
      }
      let url = this.$tools.getURL(this.$urls.doctor.setEssence, {
        meeting_id: this.meetingData.meet_info.id,
        content_page: this.meetingData.class_info.content[this.meetingData.doctor_index]
          .page,
      });
      this.$axios.get(url).then((res) => {
        this.showEssencePagePopup = false;
        // 变成精华页
        this.meetingData.class_info.content.map((item) => {
          return (item.essence = 0);
        });
        self.meetingData.class_info.content[this.meetingData.doctor_index].essence = 1;
        this.$tips.success({ text: res.msg });
      });
    },
    getDownTime() {
      let space = this.getLimitTime() - 59;
      let time = 59 - this.voiceTime.count;
      return time > 0 ? time : 0;
    },
    getUpTime() {},
    getLimitTime() {
      let time = this.meetingData.project.limit_time || 59;
      return time;
    },
    initVoiceTime() {
      if (this.voiceTime.time) {
        clearTimeout(this.voiceTime.time);
        this.voiceTime.time = null;
      }
      if (this.voiceTime.isOver) {
        this.voiceTime.isOver = false;
        return;
      }
      let count = Math.floor((this.$tools.getTime() - this.voiceTime.startTime) / 1000);
      this.voiceTime.count = count;
      if (this.voiceTime.count >= this.getLimitTime()) {
        console.log(this.meetingData?.chat_info);
        let isAutoNext =
          this.meetingData?.chat_info.chat.filter((x) => x.voice_length > 0).length <=
          this.getCourseIndex() + 1;
        this.endRecorder(count, false);
        return;
      }
      this.voiceTime.time = setTimeout(() => {
        ``;
        this.initVoiceTime();
      }, 100);
    },
    initRecordTime() {
      let count =
        this.meetingData?.chat_info.chat
          .filter((x) => x.content_type == 1)
          .slice(0, this.meetingData.class_info.content.length)
          .reduce((count, item) => count + item.voice_length, 0) || 0;
      count *= 1000;
      console.log(this.$tools.getShowTime(count, "mm:ss"));
      this.countSecond = this.$tools.getShowTime(count, "mm:ss");
    },
    initChatData(data) {
      this.initComplete = false;
      this.initRecordTime();
      let chatItem = data.chat_info.chat.find(
        (item) => item.voice_page == data.doctor_index + 1
      );
      let isRecord = !!chatItem; // 是否已经录入过
      if (isRecord) {
        this.chatContent = { ...chatItem, doctor_index: data.doctor_index,init:true };
      }
      // 如果审核打回，专家又新增了一页，那么，不自动录制
      if (data.meet_info.audit_status > 0 && !isRecord && !this.meetingData.isWatch) {
        this.showMoreChatPopup = true;
        isRecord = true;
      }
      console.log(isRecord);
      return isRecord;
    },
    initCourseStatus(data) {
      this.$nextTick(() => {
        let isRecord = this.initChatData(data);
        if (isRecord) return;
        this.chatContent = null;
        // 如果没有录音，且阶段不为0
        if (data.meet_info.phase !== 0) {
          // 防止没有
          setTimeout(() => {
            this.startRunRecord();
          }, 200);
          return;
        }
      });
    },
    isCurrentDoctor() {
      return this.$tools.getCookie("openid") == this.meetingData.meet_info.openid;
    },
    initNextCourse(data) {
      this.getContent();
      window.scrollTo(0, 0);
      let isRecord = this.initChatData(data);
      this.isNewContent = false;
      this.voiceTime.count = 0;
      if (isRecord) return;
      this.startRunRecord();
    },
    haveNotStart() {
      return this.meetingData.meet_info.phase == 0;
    },
    getCurrentTime() {
      try {
        let count = 0;
        if (this.isRecord()) count = this.voiceTime.count;
        else
          count = this.chatContent ? this.chatContent.voice_length : this.voiceTime.count;
        return count > 59 ? 60 : count;
      } catch (err) {
        console.log(err);
        return 0;
      }
    },
    getPlayText() {
      return this.isPlay ? "播放中" : "播放录制内容";
    },
    getDoctorIntroduce() {
      let item = this.meetingData.class_info.content[0];
      if (this.meetingData.doctor_index != 0 || item.template_key == "DOCTERPAGE")
        return "";
      let doctroDetail =
        this.meetingData?.meet_info.introduce ||
        `大家好，我是${this.meetingData?.meet_info.doctor_name}，来自${this.meetingData?.meet_info.hospital} ${this.meetingData?.meet_info.department}，今天由我来为大家解读这篇文献。`;
      if (item.prompt_content.includes("大家好，我是")) return "";
      else {
        return `${doctroDetail}\n`;
      }
    },
    getContent(status) {
      let doctorDetail = this.getDoctorIntroduce();
      let prompt_content =
        this.meetingData?.class_info.content[this.getCourseIndex()]?.prompt_content ?? "";
      this.content = `${doctorDetail}${prompt_content}` || "";
      // this.content =  this.meetingData?.class_info.content[this.getCourseIndex()]?.prompt_content;
      this.$nextTick(() => {
        this.$refs.itemScroll && this.$refs.itemScroll.initScroll();
      });
    },
    getProgressWidth() {
      return ((this.getLimitTime() - this.voiceTime.count) / this.getLimitTime()) * 100;
    },
    getButtonText() {
      let submit_text = this.meetingData.meet_info.submit_text || "进入讨论";
      if (this.isLastPage()) {
        return this.$tools.isPravate() ? "预备提交" : submit_text;
      }
      return "下一页";
    },
    getShowContent() {
      // let count = this.getLimitTime() - this.voiceTime.count
      // return `${Math.floor(this.voiceTime.count/1000)}秒`
    },
    getCourseIndex() {
      return this.meetingData.meet_info.isDoctor
        ? this.meetingData.doctor_index
        : this.meetingData.live_index;
    },
    isRecord() {
      return this.voiceTime?.startTime > 0;
    },
    isLastPage() {
      return (
        this.meetingData.doctor_index == this.meetingData.class_info.content.length - 1
      );
    },
    isInArea(count) {
      let space = 59 - this.voiceTime.count;
      return space < count && space >= 0;
    },
    setNextPage() {
      // if(this.meetingData.doctor_index == 0) this.setNextStep(this.$config.categary.phase.meeting_course)
      if (this.isLastPage()) {
        this.setNextStep(this.$config.categary.phase.meeting_discuss);
        return;
      }
      let page = this.meetingData.doctor_index + 1;
      this.voiceTime.isOver = false;
      let data = {
        doctor_index: page,
        live_index: page - 1,
      };
      if (this.meetingData.isWatch) {
        this.$emit("setWatchNextPage", data);
      } else {
        this.$emit("sendMessage", "meet_next", data);
      }
    },
    setNextStep(phase) {
      // this.$emit("setNextStep",{phase})
      if (this.meetingData.isWatch) {
        this.$emit("setWatchNextStep", phase);
      } else {
        this.$emit("setNextStep", { phase });
      }
    },
    startCourse() {
      if (Date.now() - this.buttonActive.start < 1000) return;
      this.buttonActive.start = Date.now();
      this.setNextStep(this.$config.categary.phase.meeting_course);
      this.startRunRecord();
    },
    nextCourse() {
      // if(this.isLastPage()) return;
      // this.$refs.itemScroll.scrollTo(0)
      this.setNextPage();
    },

    playVoice() {
      if (this.getUploadStatus()) return;
      if (Date.now() - this.buttonActive.play < 1000) return;
      if (!this.isConnect() || this.isPlay) return;
      this.buttonActive.play = Date.now();
      this.$refs.itemScroll.scrollTo(0, 200);
      if (this.chatContent) {
        this.setPlayStatus();
        this.$emit("playVoice", this.chatContent);
        return;
      }
      this.resetIndex += 1;
      this.stopRunRecord((res) => {
        this.chatContent = this.$tools.getChatContent(
          "",
          res.type,
          res.serverId,
          res.voiceLength,
          res.page || this.getCourseIndex(),
          res.localId
        );
        this.setPlayStatus();
        // this.voiceTime.count = 0
        this.$emit("playVoice", this.chatContent, true);
      }, this.voiceTime.count);
    },
    resetRecord() {
      console.log(
        this.meetingData.chat_info.chat,
        this.meetingData.chat_info.chat[this.meetingData.doctor_index]
      );
      if (this.getUploadStatus()) return;
      if (Date.now() - this.buttonActive.reset < 1000) return;
      if (!this.isConnect() || this.voiceTime.isOver) return;
      this.buttonActive.reset = Date.now();
      this.resetVoicesTimes += 1;
      this.$refs.itemScroll.scrollTo(0, 200);
      this.$emit("stopVoice");
      this.isPlay = false;
      this.chatContent = null;
      this.startRunRecord();
    },
    isConnect() {
      return this.$store.state.common.isConnect;
    },
    getUploadStatus() {
      // if(this.isUpload){
      //     // this.$tips.error({text:"上传语音中，请稍后"})
      // }
      return this.isUpload;
    },
    endCourse() {
      if (Date.now() - this.buttonActive.next < 2000) return;
      this.buttonActive.next = Date.now();
      // if(this.initComplete) return;
      // this.initComplete = true;
      if (this.getUploadStatus()) return;
      if (!this.isConnect()) return;
      if (this.voiceTime.count < 3 && this.voiceTime.startTime > 0) return;
      this.$refs.itemScroll.scroll.stop();
      console.log(this.chatContent);
      if (this.isRecord()) {
        this.endRecorder(this.voiceTime.count);
      } else {
        if(this.chatContent && !this.chatContent.init){
          this.sendChatContent();
        }
        this.voiceTime.isOver = false;
        this.$emit("stopVoice");
        this.isPlay = false;
        this.resetVoicesTimes += 1;
        this.isLastPage() ? this.setNextPage() : this.nextCourse();
      }
    },
    prepCourse() {
      if (Date.now() - this.buttonActive.next < 1000) return;
      this.buttonActive.next = Date.now();
      // console.log(this.initComplete)
      // if(this.initComplete) return;
      // this.initComplete = true;
      if (this.getUploadStatus()) return;
      if (!this.isConnect()) return;
      this.buttonActive.next = Date.now();
      if (this.voiceTime.count < 3 && this.voiceTime.startTime > 0) return;
      this.$refs.itemScroll.scroll.stop();
      this.$emit("stopVoice");
      this.isPlay = false;
      if (this.isRecord()) {
        this.endRecorder(this.voiceTime.count, false, true);
      } else {
        if (this.isNewContent) {
          this.voiceTime.isOver = false;
          this.resetVoicesTimes += 1;
          this.showPopup = true;
          return;
        }
        this.goToPrepPage(true);
      }
    },
    goToPrepPage(status) {
      console.log(status);
      console.log(this.chatContent)
      if (status && !this.chatContent.init) this.sendChatContent();
      let page = this.meetingData.doctor_index - 1;
      this.voiceTime.isOver = false;
      this.showPopup = false;
      this.$emit("sendMessage", "meet_next", {
        doctor_index: page,
        live_index: page - 1,
      });
    },
    endRecorder(count, isAutoNext = true, isShowPopup = false) {
      this.stopRunRecord((res) => {
        this.chatContent = this.$tools.getChatContent(
          "",
          res.type,
          res.serverId,
          res.voiceLength,
          res.page || this.getCourseIndex(),
          res.localId
        );
        this.isNewContent = true;
        // this.voiceTime.count = 0;
        if (isAutoNext) {
          this.sendChatContent();
          this.isLastPage() ? this.setNextPage() : this.nextCourse();
        } else {
          if (isShowPopup) this.showPopup = true;
          this.voiceTime.isOver = false;
        }
      }, count);
    },
    recorder() {
      return this.meetingData.project.record_module ? recorderH5 : recorderWx;
    },
    startRunRecord(count = 0) {
      if (count > 5) return;
      this.$nextTick(async () => {
        this.showMoreChatPopup = false;
        this.chatContent = null;
        try {
          await this.recorder().stopRecord();
        } catch (err) {}
        try {
          await this.recorder().startRecord(
            this.meetingData.meet_info.id,
            this.meetingData.doctor_index
          );
        } catch (err) {
          this.sendChatLog(err.data);
          if (err.status == 500) {
            this.startRunRecord((count += 1));
          }
          return;
        }
        this.voiceTime.startTime = this.$tools.getTime();
        this.showDownTimeTip = false;
        this.voiceTime.count = 0;
        this.runScroll();
        this.initVoiceTime();
      });
    },
    beforeUnmount() {},
    sendChatLog(content) {
      this.$emit("sendMessage", "meet_log", {
        openid: this.$store.state.common.signInfo.openid,
        name: this.$store.state.common.signInfo.name,
        content: content,
        create_time: this.$tools.getDate("", "yyyy-MM-dd hh:mm:ss"),
      });
    },
    async stopRunRecord(callback, voiceLength = 0) {
      // if(this.meetingData.doctor_index==this.meetingData.class_info.content.length - 1) this.setNextPage()
      this.voiceTime.isOver = true;
      this.showDownTimeTip = true;
      this.voiceTime.startTime = 0;
      try {
        let item = await this.recorder().stopRecord();
        let data = {};
        if (!callback) return;
        if (voiceLength != 0) {
          data = await this.uploadVoice(item.data.localId, voiceLength);
          if (!data || item.status !== 200) {
            this.$tips.error({ text: "录音失败，请重新录制" });
            this.sendChatLog({
              page: this.meetingData.doctor_index + 1,
              content: `录音失败，请重新录制`,
            });
            return;
          }
        }
        setTimeout(() => {
          callback(data);
        }, 200);
      } catch (err) {
        console.log("录音失败，请重新录制", err);
        this.$tips.error({ text: "录音失败，请重新录制" });
        this.sendChatLog({
          page: this.meetingData.doctor_index + 1,
          content: JSON.stringify(err),
        });
      }
    },
    async uploadVoice(localId, voiceLength) {
      // let name = `${this.meetingData.meet_info.meeting_number}-${this.meetingData.doctor_index + 1}-${Math.random()}`
      this.isUpload = true;
      let result;
      try {
        result = await this.recorder().upload(localId);
        if (result.status !== 200) return "";
        this.isUpload = false;
      } catch (err) {
        this.isUpload = false;
        return "";
      }
      return {
        serverId: result?.data.serverId,
        voiceLength: voiceLength,
        page: result?.data.page || 0,
        type: 1,
        localId: localId,
      };
    },
    setPlayStatus() {
      this.isPlay = true;
      let resetVoicesTimes = this.resetVoicesTimes;
      setTimeout(() => {
        // 如果重置国值，直接掐掉
        if (resetVoicesTimes != this.resetVoicesTimes) return;
        this.isPlay = false;
      }, (this.chatContent.voice_length + 1) * 1000);
    },
    sendChatContent() {
      if (!this.chatContent) return;
      this.$emit("sendMessage", "meet_chat", this.chatContent);
    },
    runScroll() {
      let maxScrollHeight = this.$refs.itemScroll.getScrollAttr("maxScrollY");
      let time = Math.ceil(Math.abs(maxScrollHeight) / 60) * 5;
      let doctorIndex = this.meetingData.doctor_index;
      let resetIndex = this.resetIndex;
      setTimeout(() => {
        if (
          doctorIndex != this.meetingData.doctor_index ||
          resetIndex !== this.resetIndex
        )
          return;
        this.$refs.itemScroll &&
          this.$refs.itemScroll.scrollTo(maxScrollHeight, time * 1000);
      }, 7 * 1000);
    },
  },
};
</script>
<style>
.p4vw .popup_content {
  padding: 4vw;
}
.p4vw .popup_button_sure {
  background: rgb(15, 48, 223);
  color: #fff;
}
.p4vw .popup_main {
  margin-top: 80vw;
}
.p4vw .popup_button_group {
  height: 10vw;
}
.meeting_course_container {
  position: relative;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
}
.meeting_course_room {
  flex: 1 0 0;
  width: 100%;
  padding-top: 4vw;
  padding-bottom: 4vw;
  overflow: hidden;
  position: relative;
}
.meeting_course_room.meeting_button_group_padding_bottom {
  padding-bottom: 8vw;
}
.meeting_course_room_proxy {
  overflow: hidden;
  position: relative;
}
.meeting_course_group {
  flex: 12vw 0 0;
  background: #fff;
  width: 100%;
  padding: 0 3.2vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.meeting_course_step {
  margin-left: 1vw;
}
.meeting_course_prep_step {
  margin-right: 1vw;
}
.meeting_course_step_detail {
  flex: 1 0 0;
  height: 8.33vw;
  margin-right: 2vw;
  display: flex;
  align-items: center;
}
.meeting_course_step_button {
  display: flex;
}
.meeting_course_step_time {
  width: 100%;
  display: flex;
}
.meeting_course_step_time > span:last-child {
  color: #0100ca;
  font-size: 4vw;
}
.meeting_course_content {
  line-height: 6vw;
  font-size: 4vw;
  white-space: pre-wrap;
}
.meeting_course_step_progress {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: center;
}
.meeting_course_step_progress_out {
  width: 100%;
  flex: 1 0 0;
  height: 1vw;
  /* border-radius:1vw; */
  background: #0100ca;
  /* margin-right:2vw; */
}
.meeting_button_group {
  margin-top: 1.067vw;
  display: flex;
  justify-content: flex-end;
}
.meeting_cream_button {
  text-align: center;
  color: #fff;
  font-size: 3.2vw;
  width: 21.333vw;
  background: #f0831e;
  border-radius: 0.5vw;
}
.meeting_course_step_progress_in {
  height: 100%;
  /* border-radius:1vw; */
  background: orange;
}
.meeting_course_point_progress_main {
  width: 6vw;
}
.meeting_course_point_progress {
  /* width:6vw; */
  display: flex;
  margin-left: 1vw;
  width: 0;
  overflow: hidden;
}

.meeting_course_point_progress_start {
  width: 6vw;
  overflow: hidden;
  animation: move 1s infinite;
  -webkit-animation: move 1s infinite;
  -moz-animation: move 1s infinite;
  -o-animation: move 1s infinite;
}
@keyframes move {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@-webkit-keyframes move {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
.meeting_course_point_progress div {
  flex: 2vw 0 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.meeting_course_title_main {
  height: 8.26vw;
  width: 100%;
  position: absolute;
  top: -9.26vw;
  left: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  padding-left: 3.2vw;
}

.meeting_course_step_play {
  /* width:28vw; */
  padding: 0 2vw;
  /* height:7vw; */
}
.meeting_course_down_time {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 4vw;
  position: fixed;
  right: 0;
  top: 4vw;
  z-index: 1000;
}
.meeting_course_down_time_block {
  width: 8vw;
  height: 8vw;
  /* font-size:4.8vw; */
  background: rgba(127, 184, 255, 1);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  line-height: 5.6vw;
}
.meeting_course_down_time_block > div:last-child > span:first-child {
  font-size: 4.8vw;
}
.meeting_course_down_time_block_full {
  width: 16vw;
  height: 16vw;
}
.meeting_course_down_time_block_normal,
.meeting_course_down_time_block_complete {
  height: 100%;
  width: 100%;
  flex-direction: column;
}
.meeting_course_down_time_block_complete {
  background: #d2d2d2;
  border-radius: 9999vw;
}
.meeting_course_container .customButton_container {
  font-size: 3.6vw;
}
.meeting_course_down_time_breathe {
  background: rgba(127, 184, 255);
  color: rgb(255, 0, 0);
  font-weight: 700;
  text-align: center;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3); */
  overflow: hidden;
  animation: breathe 1s ease-in-out infinite alternate;
  -webkit-animation: breathe 1s ease-in-out infinite alternate;
}
@keyframes breathe {
  0% {
    opacity: 1;
    /* box-shadow: 0 1px -15px rgba(127,184,255, 1); */
    box-shadow: 0 1px -8px rgba(0, 0, 255, 1);
  }
  100% {
    opacity: 1;
    box-shadow: 0 1px 16px rgba(0, 0, 255, 1);
  }
}
@-webkit-keyframes breathe {
  0% {
    opacity: 1;
    box-shadow: 0 1px -8px rgba(0, 0, 255, 1);
  }
  100% {
    opacity: 1;
    box-shadow: 0 1px 16px rgba(0, 0, 255, 1);
  }
}
.disableBtn {
  background: #ccc !important;
  /* color: #000; */
  border-radius: 1vw;
}
.meeting_course_formal_tips_save {
  width: 100%;
  padding: 0 4vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: -9.26vw;
  z-index: 50;
  height: 8.26vw;
  background: #7fb8ff;
}
.meeting_course_formal_tips_save_text_second {
  font-size: 3.2vw;
}
.meeting_course_formal_tips_save_text_second_item {
  background: #f0831e;
  padding: 0 2vw;
  color: #fff;
  font-size: 2.8vw;
  border-radius: 0.5vw;
}
.meeting_course_formal_tips_save_text_second_item_second {
  font-weight: 700;
}
</style>
